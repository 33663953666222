// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&family=Jost:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://unpkg.com/aos@next/dist/aos.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css");

// @font-face {
//   font-family: "SF Pro";
//   src: url("../fonts/SF-Pro-Text-Regular.otf") format("opentype");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "SF Pro";
//   src: url("../fonts/SF-Pro-Text-Semibold.otf") format("opentype");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "SF Pro";
//   src: url("../fonts/SF-Pro-Text-Medium.otf") format("opentype");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "SF Pro";
//   src: url("../fonts/SF-Pro-Text-Bold.otf") format("opentype");
//   font-weight: 700;
//   font-style: normal;
// }

html {
  overflow-x: hidden;
}

body {
  font-family: "DM Sans", "sans-serif";
  overflow: hidden;
}

main {
  background: #fff;
  @media (min-width: 1024px) {
    padding-top: 153px;
  }
  padding-top: 124px;
}

footer {
  background: #3a6ea5;
}

.hero-bg {
  background-image: url("../img/hero-bg.png");
  background-position: center center;
  background-size: cover;
}

.courses-bg {
  background-image: url("../img/course-bg.svg");
  @media (min-width: 1280px) {
    background-position: bottom center;
    background-size: 100%;
  }
  background-size: cover;
  background-repeat: no-repeat;
}

.tutors-bg {
  background-image: url("../img/course-bg.svg");
  @media (min-width: 1280px) {
    background-position: top center;
    background-size: 100%;
  }
  background-size: cover;
  background-repeat: no-repeat;
}

.pattern-top-bg {
  background-image: url("../img/course-bg.svg");
  @media (min-width: 1280px) {
    background-position: top center;
    background-size: 100%;
  }
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-bg {
  background-image: url("../img/footer-grid.svg");
  background-position: center center;
  background-size: cover;
}

.page-header {
  background-image: url("../img/small-header-bg.png");
  background-position: top center;
  background-size: cover;
}

.bg-gradient-cyan {
  background-image: url("../img/bg-gradient-cyan.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.bg-gradient-cyan-top {
  background-image: url("../img/bg-gradient-cyan-top.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.bg-gradient-blue {
  background-image: url("../img/bg-gradient-blue.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.bg-gradient-blue-top {
  background-image: url("../img/bg-gradient-blue-top.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.bg-gradient-purple {
  background-image: url("../img/bg-gradient-purple.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.bg-gradient-purple-top {
  background-image: url("../img/bg-gradient-purple-top.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.bg-gradient-purple-full {
  background-image: url("../img/bg-gradient-purple-full.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.timeline {
  background-image: url("../img/timeline.svg");
  background-position: center center;
  background-size: 100% 100%;
}

@media screen and (min-width: 1025px) {
  [data-aos] {
    opacity: 0; /* Initially set opacity to 0 */
    transform: translateY(20px); /* Example: Slide down effect */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Specify the animation transition */
  }

  [data-aos="fade-up"].aos-animate {
    opacity: 1; /* Set opacity to 1 when the animation is triggered */
    transform: translateY(0); /* Example: Reset the transformation */
  }
}

.progress-container {
  position: relative;
  width: 131px;
  height: 131px;
}

.progress-bar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    transparent 0%,
    #2edb4b 0% var(--progress),
    #eeeeee var(--progress) 100%
  );
  border: 15px solid #fafafa;
  position: relative;
  overflow: hidden;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  background: white;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 50%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tab {
  &.active {
    background-color: white;
    color: black;
  }
}

.owl-dots {
  display: inline-flex;
  gap: 8px;
  width: 100%;
  justify-content: center;
}
.owl-carousel button.owl-dot {
  background: #c0c0c0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &.active {
    background: #2f2f2f;
  }
}

.hero-carousel {
  position: relative;
  .owl-dots {
    @media screen and (min-width: 1025px) {
      position: absolute;
      top: 15px;
      right: 30%;
      width: fit-content;
    }
  }
}

.toggler {
  .active {
    background-color: #ff6700;
    color: #fff;
  }
}

.hidden {
  display: none;
}

.dropdown-menu {
  display: none;
}
.dropdown-menu.show {
  display: inline-flex;
}
